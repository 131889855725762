<template>
  <section class="container table-block-products products-list" :class="theme_color">
    <products-list-header v-if="products.results && products.results.length"/>
    <products-list-item v-for="p in products.results" :key="p.id" :product="p" :stocks="stocks" />
  </section>
</template>

<script>

import ProductsListItem from "@/components/catalog/products-list-item";
import ProductsListHeader from "@/components/catalog/products-list-header";
import {Product} from "@/models/product";
import {Stock} from "@/models/stock";

export default {
  name: 'BlockTableProducts',
  components: {ProductsListHeader, ProductsListItem},
  props: ['value', 'theme'],
  data() {
    return {
      products: {},
      stocks: []
    }
  },
  computed: {
    theme_color() {
      return this.theme.value.theme
    }
  },
  async fetch() {
    try {
      let product_codes = this.value.product_codes;
      [this.stocks, this.products] = await Promise.all([
        Stock.config({count_loading: true}).get(),
        Product.config({count_loading: true}).params({product_codes: product_codes}).first()
      ]);
    } catch (e) {
    }
  }
}
</script>
